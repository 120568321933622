import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const TemplatePage = Loadable(lazy(() => import('pages/template')));
const CreateTemplatePage = Loadable(lazy(() => import('pages/createTemplate')));
const ComparePage = Loadable(lazy(() => import('pages/compare')));
const RequestPage = Loadable(lazy(() => import('pages/request')));
// const DashboardPage = Loadable(lazy(() => import('pages/dashboard/default')));
const InactivePage = Loadable(lazy(() => import('pages/inactive_template')));
const AppContactUS = Loadable(lazy(() => import('pages/contact-us')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        // {
        //   path: "dashboard",
        //   children: [
        //     {
        //       path: "default",
        //       element: <DashboardDefault />,
        //     },
        //     {
        //       path: "analytics",
        //       element: <DashboardAnalytics />,
        //     },
        //   ],
        // },
        {
          path: '/',
          element: <RequestPage />,
        },
        // {
        //   path: "dashboard",
        //   element: <DashboardPage />,
        //   rights: "ngtRequest",
        // },
        {
          path: 'overview',
          element: <RequestPage />,
          rights: 'ngtRequest',
        },
        {
          path: 'overview/:id/compare',
          element: <ComparePage />,
          rights: 'ngtCompare',
        },
        {
          path: 'compare/:id/:type?',
          element: <ComparePage />,
          rights: 'ngtCompare',
        },
        {
          path: 'compareTemplates',
          element: <TemplatePage />,
          rights: 'ngtTemplate',
        },
        {
          path: 'compareTemplates/create',
          element: <CreateTemplatePage />,
          rights: 'ngtTemplate',
        },
        {
          path: 'compareTemplates/:id/edit',
          element: <CreateTemplatePage />,
          rights: 'ngtTemplate',
        },
        {
          path: 'compareTemplates/inactive',
          element: <InactivePage />,
          rights: 'ngtTemplate',
        },
        {
          path: '*',
          element: <RequestPage />,
          rights: 'ngtRequest',
        },
      ],
    },
    // {
    //   path: "/maintenance",
    //   element: <CommonLayout />,
    //   children: [
    //     {
    //       path: "404",
    //       element: <MaintenanceError />,
    //     },
    //     {
    //       path: "500",
    //       element: <MaintenanceError500 />,
    //     },
    //     {
    //       path: "under-construction",
    //       element: <MaintenanceUnderConstruction />,
    //     },
    //     {
    //       path: "coming-soon",
    //       element: <MaintenanceComingSoon />,
    //     },
    //   ],
    // },
    // {
    //   path: "/auth",
    //   element: <CommonLayout />,
    //   children: [
    //     {
    //       path: "login",
    //       element: <AuthLogin />,
    //     },
    //     {
    //       path: "register",
    //       element: <AuthRegister />,
    //     },
    //     {
    //       path: "forgot-password",
    //       element: <AuthForgotPassword />,
    //     },
    //     {
    //       path: "reset-password",
    //       element: <AuthResetPassword />,
    //     },
    //     {
    //       path: "check-mail",
    //       element: <AuthCheckMail />,
    //     },
    //     {
    //       path: "code-verification",
    //       element: <AuthCodeVerification />,
    //     },
    //   ],
    // },
    {
      path: '/',
      element: <CommonLayout layout='simple' />,
      children: [
        {
          path: 'contact-us',
          element: <AppContactUS />,
        },
      ],
    },
  ],
};

export default MainRoutes;
