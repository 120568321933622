import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";

// project import
import useAuth from "hooks/useAuth";
import Loader from "components/Loader";


// types
import { GuardProps } from "types/auth";
import { createToken, encrypt } from "utils/commonFunctions";

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: GuardProps) => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [accessGranted, setAccessGranted] = useState(false);
  const host = window.location.host;
  const parts = host.split(".");
  parts.shift();    
  const domain = parts.join('.')

  const getUserAccessRole = async () => {
    try {
      console.log(`${JSON.parse(process.env.REACT_APP_ACCOUNT_API_URL!)[domain]}/users/user-profile`);
      let code = {"code":"COM"}
      let tokenUser = createToken(code);
      let encryptedTokenUser = encrypt(tokenUser);
      let encrDataUser = new FormData();
      encrDataUser.append("encrToken", encryptedTokenUser);
      const userResp = await axios.post(`${JSON.parse(process.env.REACT_APP_ACCOUNT_API_URL!)[domain]}/users/user-profile`, encrDataUser, { withCredentials: true });
      console.log("userResp", userResp);
      return userResp.data.data;
    } catch (err) {
      console.error("Error in getting access role:", err);
      throw err;
    }
  };

  useEffect(() => {
    console.log("process.env.REACT_APP_ACCOUNT_API_URL:",process.env.REACT_APP_ACCOUNT_API_URL);
    
    const cookieExist = Cookies.get(process.env.REACT_APP_EXPIRE_COOKIE || "");
    if (cookieExist) {
      getUserAccessRole().then((response) => {
        console.log(response);
        
        if (response && response[0]?.permissions?.Allow.includes('compare.login.*')) {
          console.log("Compare Access allowed");
          setAccessGranted(true);
        } else {
          console.log("Compare Access denied");
          const profileUrl = `${process.env.REACT_APP_ACCOUNT_LOGIN}${domain}#/profile`
          window.location.assign(profileUrl);
        }
      }).catch(err => {
        console.error("Error in getting access role:", err);
      });
    } else {
      const externalUrl = `${process.env.REACT_APP_ACCOUNT_LOGIN}${domain}#/auth/logout/${encodeURIComponent(encrypt(window.location.href))}` || "/";
      window.location.assign(externalUrl);
    }
  }, [isLoggedIn, navigate, location]);

  if (accessGranted) {
    return children;
  }
  return <Loader />;
};

export default AuthGuard;
